import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';

const Header = () => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed">
                <Toolbar>

                        <HomeIcon size="large" sx={{ mr: 2 }}/>

                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Rooms
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default Header;
