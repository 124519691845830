import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';

import Header from './Header';
import Rooms from './Rooms';

import { Grid } from '@mui/material';
import { grey } from '@mui/material/colors';

import { useEffect, useState } from 'react';
import fetch_data from './fetch_data';
import { try_fetch } from './fetch_data';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';


function App() {

  const [roomsData, setRoomsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!await try_fetch()) {
        // token not valid. Rediret!
        window.location.replace("https://sso.kanxu.de?redirect=https://home.kanxu.de");
      }

      const data = await fetch_data();
      setRoomsData(data);
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <Grid container sx={{ bgcolor: grey[100] }}>
      <Grid item xs={false} sm={2} lg={3} />
      <Grid item xs={12} sm={8} lg={6}>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <Header />
          </Grid>
          <Grid item mt={5}>
            {roomsData.length > 0 ?
              (<Rooms roomsData={roomsData} />) :
              (<Stack spacing={2} mt={4}>
                <Skeleton variant="rounded" height={100} animation="wave" />
                <Skeleton variant="rounded" height={100} animation="wave" />
                <Skeleton variant="rounded" height={100} animation="wave" />
                <Skeleton variant="rounded" height={100} animation="wave" />
                <Skeleton variant="rounded" height={100} animation="wave" />
                <Skeleton variant="rounded" height={100} animation="wave" />
                <Skeleton variant="rounded" height={100} animation="wave" />
                <Skeleton variant="rounded" height={100} animation="wave" />
              </Stack>)
            }

          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={false} sm={2} lg={3} />

    </Grid>
  );
}

export default App;
