
export const urlMetaData = "/api/v1/meta";
export const urlMi = "/api/v1/mi";
export const urlMax = "/api/v1/max";

async function try_fetch() {
    const resp = await fetch(urlMetaData, {
        redirect: "manual",
    });
    return resp.ok;
}

export {try_fetch};


async function fetch_data() {

    // data
    const meta = await (await fetch(urlMetaData)).json();
    const mi = await (await fetch(urlMi)).json();
    const max = await (await fetch(urlMax)).json();
    // console.log(meta);

    // sort data
    const roomsData = meta.map((e) => {
        let r = Object();

        r.name = e.name;
        r.id = e.id;
        r.category = e.category;

        if ("mi" in e) {
            // find mi data
            const the_mi_data = mi.find((m) => {
                return e.mi === m.id;
            });

            if (the_mi_data !== void 0) {
                r.mi = the_mi_data;
            }
        }

        if ("max" in e) {
            const the_max_data = max.find((m) => {
                return e.max === m.rf_address;
            });

            if (the_max_data !== void 0) {
                r.max = the_max_data;
            }
        }

        return r;
    });

    // console.log(roomsData);


    return roomsData;
}

export default fetch_data;