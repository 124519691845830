import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';

import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import AdjustIcon from '@mui/icons-material/Adjust';
import WaterIcon from '@mui/icons-material/Water';
import { Grid } from '@mui/material';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import MaxConf from './MaxConf';
import { useState } from 'react';
import Modal from '@mui/material/Modal';

import ChairIcon from '@mui/icons-material/Chair';
import BedIcon from '@mui/icons-material/Bed';
import BalconyIcon from '@mui/icons-material/Balcony';
import GarageIcon from '@mui/icons-material/Garage';
import StorageIcon from '@mui/icons-material/Storage';
import CountertopsIcon from '@mui/icons-material/Countertops';
import WorkIcon from '@mui/icons-material/Work';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';

const GreenBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },
}));


const YellowBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#ffb60d',
        color: '#ffb60d',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },
}));

const GreyBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#ababab',
        color: '#ababab',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },
}));

const RoomIconMap = {
    living: (<><ChairIcon /></>),
    bed: (<BedIcon />),
    balcony: (<BalconyIcon />),
    garage: (<GarageIcon />),
    basement: (<StorageIcon />),
    kitchen: (<CountertopsIcon />),
    office: (<WorkIcon />),
}

const determBadge = (roomData) => {
    const dt_mi = new Date(roomData.mi.time);
    const now = new Date();
    const delta = now - dt_mi;

    if (delta < 300000) {
        return (
            <GreenBadge badgeContent=" " overlap="circular" variant='dot'>
                <Avatar sx={{ bgcolor: "primary.main" }}>
                    {
                        RoomIconMap[roomData.category]
                    }
                </Avatar>
            </GreenBadge>
        );
    } else if (delta < 600000) {
        return (
            <YellowBadge badgeContent=" " overlap="circular" variant='dot'>
                <Avatar sx={{ bgcolor: "primary.main" }}>
                    {
                        RoomIconMap[roomData.category]
                    }
                </Avatar>
            </YellowBadge>
        );
    } else {
        return (
            <GreyBadge badgeContent=" " overlap="circular" variant='dot'>
                <Avatar sx={{ bgcolor: "primary.main" }}>
                    {
                        RoomIconMap[roomData.category]
                    }
                </Avatar>
            </GreyBadge>
        );
    }

}

const Room = ({ roomData }) => {

    const [confDlgOpen, setConfDlgOpen] = useState(false);
    const [maxData, setMaxData] = useState(roomData.max);

    const handleConfDlgClose = () => { setConfDlgOpen(false); }
    const handleConfDlgOpen = () => { setConfDlgOpen(true); }

    return (
        <>
            <Modal
                open={confDlgOpen}
                onClose={handleConfDlgClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <MaxConf roomName={roomData.name} maxData={maxData} handleClose={handleConfDlgClose} setMaxData={setMaxData} />

            </Modal>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={
                            determBadge(roomData)
                        }
                        action={
                            'max' in roomData ? (<>
                                <IconButton aria-label="settings" onClick={handleConfDlgOpen}>
                                    <SettingsIcon />
                                </IconButton>
                            </>) : null
                        }
                        title={roomData.name}

                    />
                    <CardContent>
                        <Grid container alignItems={'center'} spacing={2}>

                            {
                                'mi' in roomData ? (<>
                                    <Grid item>
                                        <ThermostatIcon fontSize='medium' />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h7">{roomData.mi.temperature}°C</Typography>
                                    </Grid>
                                    <Grid item>
                                        <WaterIcon fontSize='medium' />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h7">{roomData.mi.humidity}%</Typography>
                                    </Grid>
                                </>
                                ) : null
                            }
                            {
                                'max' in roomData ? (<>
                                    <Grid item>
                                        <AdjustIcon fontSize='medium' />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h7">{maxData.temperature_set}°C</Typography>
                                    </Grid>
                                </>) : null
                            }
                            {
                                ('mi' in roomData && roomData.mi.battery < 20) || ('max' in roomData && roomData.max.battery_low)?
                                (<Grid item>
                                    <BatteryAlertIcon sx={{ color: 'red' }} />
                                </Grid>):null
                            }

                        </Grid>

                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default Room;