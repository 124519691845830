import * as React from 'react';

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

import { urlMax } from './fetch_data';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 'sm',
    bgcolor: 'background.paper',
    border: '1px solid #555',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};


const MaxConf = React.forwardRef((props, ref) => {

    const [tp, setTp] = useState(props.maxData.temperature_set);
    const [busy, setBusy] = useState(false);

    const handleArrowUp = () => {
        setTp(tp + 0.5);

    }

    const handleArrowDown = () => {
        setTp(tp - 0.5);
    }

    const handleApplySetting = () => {

        const changeSetting = async () => {
            const url = `${urlMax}/${props.maxData.rf_address}` ;
            // console.log(url);

            const resp = await fetch(url, {
                method: "PUT",
                body: JSON.stringify({
                    temperature: tp,
                }),
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if (resp.ok) {
                let maxData = props.maxData;
                maxData.temperature_set = tp;
                props.setMaxData(maxData);
                props.handleClose();
            }
        };

        setBusy(true);
        changeSetting().catch(console.error).finally(() => {
            setBusy(false);
        });
    }



    return (

        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                {props.roomName}
            </Typography>
            <Grid container alignItems={'center'} my={4}>
                <Grid item xs={2} />

                <Grid item xs={2} textAlign={'right'}>
                    <Button variant='contained' onClick={handleArrowDown}>
                        <KeyboardArrowDownIcon />
                    </Button>
                </Grid>
                <Grid item xs={4} textAlign={'center'}>
                    <Typography id="modal-modal-description" variant='h5' mx={2}>
                        {tp.toFixed(1)}°C
                    </Typography>
                </Grid>
                <Grid item xs={2} textAlign={'left'}>
                    <Button variant='contained' onClick={handleArrowUp}>
                        <KeyboardArrowUpIcon />
                    </Button>
                </Grid>

                <Grid item xs={2} />


            </Grid>
            <Box justifyContent={'flex-end'} sx={{ display: 'flex' }}>
                <Button
                    onClick={handleApplySetting}
                    variant='contained'
                    sx={{ mr: 2 }}
                    disabled={busy}
                >
                    Apply
                    {
                        busy && (
                            <CircularProgress size={24} sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }} />)
                    }
                </Button>
                <Button onClick={props.handleClose}>Close</Button>
            </Box>

        </Box>
    )
});

export default MaxConf;
