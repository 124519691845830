import * as React from 'react';
import { Grid } from '@mui/material';

import Room from './Room';

const Rooms = ({ roomsData }) => {

    const rooms = roomsData.map((r) => <Room key={r.id} roomData={r} />)

    return (
        <>
            <Grid container spacing={1} direction={'column'} p={2}>
                {rooms}
            </Grid>
        </>
    );
}

export default Rooms;